import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_BlueIris_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Software/Windows/Blue_Iris_v5/PrimaryBox';
import NavButtons from 'components/Software/Windows/NavButtons';
import ForumBox from 'components/Software/Windows/Blue_Iris/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "BlueIris Version 5",
  "path": "/Software/Windows/Blue_Iris_v5/",
  "dateChanged": "2020-06-10",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_Blue_Iris.png",
  "social": "/images/Search/P_SearchThumb_Blue_Iris.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-Blue-Iris_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Blue Iris v5' dateChanged='2020-06-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_Blue_Iris.png' twitter='/images/Search/P_SearchThumb_Blue_Iris.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/Blue_Iris_v5/' locationFR='/fr/Software/Windows/Blue_Iris_v5/' crumbLabel="Blue Iris" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "blue-iris-v5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#blue-iris-v5",
        "aria-label": "blue iris v5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Blue Iris v5`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#software"
        }}>{`Software`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#windows"
            }}>{`Windows`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#blue-iris-v5"
                }}>{`Blue Iris v5`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#blue-iris-setup"
            }}>{`Blue Iris Setup`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#configure-the-blueiris-webserver"
            }}>{`Configure the BlueIris Webserver`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#access-to-the-live-video-of-one-camera-or-all-cameras"
            }}>{`Access to the live video of one camera or all cameras`}</a></li>
        </ul>
      </li>
    </ul>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul"><strong parentName="li">{`UPDATE`}</strong>{`: If you are using a newer camera model (2K+ WQHD and up) you, currently, have to manually add the PTZ CGI commands to pan, tilt or zoom your camera (if those functions are supported by your model). You can find a setup guide in our `}<a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_with_BlueIris_v5/"
        }}>{`FAQ section`}</a>{`.`}</li>
      <li parentName="ul">{`If you want to use your INSTAR MQTT camera to connect with the BlueIris MQTT service, please follow this link: `}<a parentName="li" {...{
          "href": "/en/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/"
        }}>{`Blue Iris and INSTAR MQTT`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "blue-iris-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#blue-iris-setup",
        "aria-label": "blue iris setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Blue Iris Setup`}</h2>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://blueirissoftware.com/#download"
      }}>{`download the BlueIris v5 Software from here`}</a>{`. Double-click the file and install the software on your system. Start the software and click on the menu icon next to the BlueIris logo to add your camera:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_01.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Add a name for your camera and a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`shortname`}</code>{`. Select `}<strong parentName="p">{`Network IP`}</strong>{` as camera type and click on `}<strong parentName="p">{`OK`}</strong>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_02.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: The `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`shortname`}</code>{` is part of the URL that we will later need to access our cameras live video as well as sending MQTT commands to BlueIris to interact with our camera.`}</p>
    </blockquote>
    <p>{`Type in your cameras IP address, the admin login and select the correct `}<strong parentName="p">{`INSTAR Preset`}</strong>{`. Make sure that you set the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`RTSP Port`}</a>{` (default `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`554`}</code>{`) and `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/ONVIF/"
      }}>{`ONVIF Port`}</a>{` (default `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8080`}</code>{`) according to your camera configuration:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_03.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Continue adding all your cameras. Select a camera port to take control over it's Pan&Tilt functions - including preset positions. Double click a port to view the camera in full view:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_04.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <h2 {...{
      "id": "configure-the-blueiris-webserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configure-the-blueiris-webserver",
        "aria-label": "configure the blueiris webserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure the BlueIris Webserver`}</h2>
    <p>{`To use the Webserver we first have to create a user account. This can be done in the `}<strong parentName="p">{`Software Settings`}</strong>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_05.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Go to `}<strong parentName="p">{`Users`}</strong>{` and click to add a new user by typing in a username and password:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_06.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Now switch to the `}<strong parentName="p">{`Web Server`}</strong>{` tab, enable the `}<strong parentName="p">{`HTTP Service`}</strong>{` and set a HTTP Port, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8181`}</code>{`, you want to use to access the BlueIris Dashboard:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_07.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Under `}<strong parentName="p">{`Advanced`}</strong>{` you can deactivate the login page (this activates the basic authentication) and whitelist IP address you want to be able to access the BlueIris Dashboard:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_08.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Type in the address of your Windows PC (that runs BlueIris), add the port that you just defined and log in with your BlueIris user account:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_09.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <p>{`The BlueIris Dashboard gives you access to your cameras live streams, pan&tilt function as well as allowing you to preview alarm recordings:`}</p>
    <p><img parentName="p" {...{
        "src": "./Blue_Iris/BlueIris5_10.png",
        "alt": "Blue Iris for your INSTAR IP Camera"
      }}></img></p>
    <h2 {...{
      "id": "access-to-the-live-video-of-one-camera-or-all-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#access-to-the-live-video-of-one-camera-or-all-cameras",
        "aria-label": "access to the live video of one camera or all cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access to the live video of one camera or all cameras`}</h2>
    <p>{`If you want to integrate the live video of your camera into a third party software, you can also do this directly. For example, if you have the BlueIris user `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`user`}</code>{`/`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`password`}</code>{` and the Dashboard is running on the IP `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.178.21:8181`}</code>{`, you can access the video directly using the following URLs:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://user:password@192.168.178.21:8181/mjpg/Index`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://user:password@192.168.178.21:8181/mjpg/cameraname`}</code></li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://user:password@192.168.178.21:8181/image/Index`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://user:password@192.168.178.21:8181/image/cameraname`}</code></li>
    </ul>
    <p>{`With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Index`}</code>{` at the end of the URL you get an overview of all cameras. If you replace the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Index`}</code>{` with the `}<strong parentName="p">{`short name`}</strong>{` of a camera, you will only see the live video of this camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      